import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Writing = () => (
  <Layout>
    <SEO title="Kevin Young: Writing" />
    <section>
      <div className="boxed-container">
        <div className="text-block">
          <p>Regardless of the application, be it a feature article, a personal, company or product profile, artist bio or technology review, whatever the topic or format, whoever the client, my job is to wrap my arms around the subject and gain a thorough understanding of it.</p>
          <p>In order to write effectively about the something I’m covering, I need to have a clear picture of the people behind that something - who they are, what they do, why and how they do it, and what they want to convey to the reader.</p>
          <p>To my mind, the best way to do that is to ask a lot of questions, to listen - to be willing to, if necessary, go down a few rabbit holes to get not only the information I need for the piece, but the context and additional details required to convey those things to the intended audience.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default Writing